import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  cta?: ButtonProps
  image?: ImageProps
  style?: any
  title?: string
}

export const Item = memo(function Item({
  className,
  cta,
  style,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container row dial={5} className={className} style={style}>
      <FadeIn>
        <Title>{title}</Title>
      </FadeIn>

      {cta ? (
        <InnerBottom>
          <FadeInUp>
            <Button {...cta} variant="inverted" />
          </FadeInUp>
        </InnerBottom>
      ) : null}
    </Container>
  )
})

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 5rem;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.2;
  transition: opacity 0.6s ease-in-out;

  @media (max-width: 1199px) {
    font-size: 3.75rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`

const InnerBottom = styled.div`
  opacity: 0.2;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.6s ease-in-out;
  z-index: 4;
`

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight2};
  &.active {
    ${Title}, ${InnerBottom} {
      opacity: 1;
    }
  }
`
