import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Star } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  idx?: number
  image?: ImageProps
  languageCode: string
  place?: string
  rating?: number
  subtitle?: string
  title?: string
  websiteURL?: string
}

export const Item = memo(function Item({
  image,
  place,
  rating,
  subtitle,
  title,
  websiteURL,
}: Props) {
  const starsArray = Array.from(Array(rating), (_, i) => i * 1)

  return (
    <Container>
      <Inner row={false} dial={5}>
        {rating ? (
          <FadeInUp>
            <Rating row>
              {starsArray.map((_item, index) => (
                <StarItem key={index}>
                  <Star />
                </StarItem>
              ))}
            </Rating>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
        {place ? (
          <FadeInUp>
            <Place
              aria-label={place}
              href={websiteURL}
              rel="noopener"
              target="_blank"
            >
              {place}
            </Place>
          </FadeInUp>
        ) : null}
      </Inner>

      {image ? (
        <LazyLoadComponent>
          <StyledImage {...image} />
        </LazyLoadComponent>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: relative;
  width: 100%;
  height: 47vh;

  @media (max-width: 767px) {
    max-height: 25rem;
  }
`

const Inner = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 1.5rem;
  z-index: 1;
`

const Rating = styled(FlexBox)`
  margin-bottom: 0.9375rem;
`

const StarItem = styled.div`
  svg {
    width: 0.4375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.primaryLight};
    margin-right: 0.5rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  line-height: 2.4375rem;
  text-align: center;
  text-transform: uppercase;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  line-height: 2.4375rem;
  text-align: center;
  text-transform: uppercase;
`

const Place = styled.a`
  display: block;
  margin-top: 1.2rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05em;
  text-align: center;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

const StyledImage = styled(Image)`
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    backface-visibility: hidden;
    opacity: 0.5;
  }
`
