import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Image } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Item, Props as ItemProps } from './item'

export interface Props {
  label?: string
  locations: ItemProps[]
}

export const LocationsSlider = memo(function LocationsSlider({
  label,
  locations,
}: Props) {
  if (!locations) {
    return null
  }

  const containerRef = useRef<any>()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1200,
    },
    drag: false,
    loop: locations.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      locations.length > 1 ? setDetails(s.track.details) : null
    },
  })

  const [thumbsSliderRef, thumbsRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2800,
    },
    drag: false,
    loop: locations.length > 1 ? true : false,
    slides: {
      origin: 'center',
      perView: 2.05,
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 1,
        },
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    setLoaded(newLoaded)
  }, [currentSlide, galleryRef, sliderRef, thumbsRef, thumbsSliderRef])

  return (
    <Container className="blended" ref={containerRef}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Blended>
          {label ? (
            <InnerTop>
              <FadeInUp>
                <Label>{label}</Label>
              </FadeInUp>
            </InnerTop>
          ) : null}

          <Carousel ref={sliderRef}>
            {uniq(locations).map((item, index) =>
              item.image ? (
                <Slide key={index} className="keen-slider__slide">
                  <Inner style={positionStyle(index)}>
                    <Image
                      animationStart={
                        loaded[index] || nextLoaded[index] ? true : false
                      }
                      media="(min-width: 2800px)"
                      {...(loaded[index] || nextLoaded[index]
                        ? item.image
                        : null)}
                    />
                    <Spinner />
                  </Inner>
                </Slide>
              ) : null,
            )}
          </Carousel>

          {locations.length > 2 ? (
            <Arrows row space="between">
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() ||
                  galleryRef.current?.prev() ||
                  thumbsRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() ||
                  galleryRef.current?.next() ||
                  thumbsRef.current?.next()
                }
              />
            </Arrows>
          ) : null}

          <Thumbs ref={thumbsSliderRef}>
            {uniq(locations).map((item, index) =>
              item.title ? (
                <Item
                  key={index}
                  className={`keen-slider__slide${
                    currentSlide === index ? ' active' : ''
                  }`}
                  {...item}
                />
              ) : null,
            )}
          </Thumbs>
        </Blended>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  overflow: hidden;
`

const Blended = styled.div`
  @media (min-width: 1200px) {
    mix-blend-mode: difference;

    @supports (font: -apple-system-body) and (-webkit-appearance: none) {
      mix-blend-mode: luminosity;
    }
  }
`

const InnerTop = styled.div`
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.3em;
  line-height: 1.125rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  text-transform: uppercase;
`

const Carousel = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 56.25rem;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    opacity: 0.3;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 55.5%;
    background: linear-gradient(rgba(15, 15, 15, 0) 0%, #000 100%);
    opacity: 0.7;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    height: 37.5rem;
  }
`

const Slide = styled.div`
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
  transform: translateY(-50%);
  z-index: 3;

  @media (min-width: 1200px) {
    left: 17.569vw;
    right: 17.569vw;
  }
`

const Thumbs = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: visible;
  z-index: 2;
`
