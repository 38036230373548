import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  title?: string
  subtitle?: string
  variant?: Variant
}

export const Item = memo(function Item({
  cta,
  description,
  image,
  label,
  title,
  subtitle,
  variant = 'default',
}: Props) {
  return (
    <Wrapper variant={variant}>
      <Container row space="between" reverse={variant === 'inverted'}>
        <Pattern src={'/logo-pattern-zigzag.svg'} variant={variant} />

        <ParallaxProvider>
          <StyledParallax translateY={['-70px', '120px']}>
            <Box variant={variant}>
              <FadeInUp>
                <Inner>
                  {label ? (
                    <Row row dial={4}>
                      <Label>{label}</Label>
                    </Row>
                  ) : null}
                  {title ? <Title>{title}</Title> : null}
                  {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
                  {description ? (
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  ) : null}
                  {cta ? <StyledButton {...cta} /> : null}
                </Inner>
              </FadeInUp>
            </Box>
          </StyledParallax>
        </ParallaxProvider>

        {image ? <StyledImage {...image} /> : null}
      </Container>
    </Wrapper>
  )
})

const Wrapper = styled.div<ContainerProps>`
  margin: 9.75rem 10.833vw;
  margin-left: calc(10.833vw - 5rem);

  @media (max-width: 1199px) {
    margin: 6rem 0;
    margin-left: 0;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          margin-left: 10.833vw;
          margin-right: calc(10.833vw - 5rem);

          @media (max-width: 1199px) {
            margin-right: 0;
          }
        `
    }
  }}
`

const Container = styled(FlexBox)`
  position: relative;
  max-width: calc(70.5rem + 5rem);
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Pattern = styled.img<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 1199px) {
    display: none;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          left: unset;
          right: 0;
        `
    }
  }}
`

const StyledParallax = styled(Parallax)`
  width: 53.5%;

  @media (max-width: 992px) {
    width: 100%;
    transform: none !important;
  }
`

const Box = styled.div<ContainerProps>`
  position: relative;
  left: 5rem;
  top: 6.25rem;
  width: 100%;
  padding: 5rem 3.75rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
  z-index: 2;

  @media (max-width: 1199px) {
    top: 0;
    left: 0;
  }

  @media (max-width: 992px) {
    padding-top: 0;
    padding-bottom: 3.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 480px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'inverted':
        return css`
          left: unset;
          right: 5rem;

          @media (max-width: 1199px) {
            right: 0;
          }
        `
    }
  }};
`

const Inner = styled.div`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const Row = styled(FlexBox)`
  margin-bottom: 1.5625rem;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.0625rem;
    background-color: ${({ theme }) => theme.colors.variants.primaryDark};
  }
`

const Label = styled.div`
  min-width: max-content;
  margin-right: 1.25rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.3em;
  line-height: 1.125rem;
  text-transform: uppercase;

  @media (max-width: 992px) {
    margin-right: 0;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 1.8125rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.1875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 1.8125rem;
  }
`

const Description = styled.div`
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.875rem;
`

const StyledButton = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 480px) {
    width: 100%;
    max-width: 100%;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: 46.5%;
  max-height: 56.25rem;
  max-width: 35.25rem;
  aspect-ratio: 564/900;
  z-index: -1;

  @media (max-width: 992px) {
    width: 100%;
    max-width: 25rem;
    aspect-ratio: 720/900;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'inverted'
