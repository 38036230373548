import styled from '@emotion/styled'
import { FollowUs } from 'app/components/FollowUs'
import { Hero } from 'app/components/Hero'
import { HotelsPreview } from 'app/components/HotelsPreview'
import { HotelsPreviewMobile } from 'app/components/HotelsPreviewMobile'
import { Intro } from 'app/components/Intro'
import { LocationsSlider } from 'app/components/LocationsSlider'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ZigZags } from 'app/components/ZigZags'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <SEO {...context.seoProps} />
        {context.structuredDataProps ? (
          <StructuredData
            languageCode={pageContext.languageCode}
            {...context.structuredDataProps}
          />
        ) : null}
        {context.headerProps ? (
          <Header
            languageCode={context.languageCode}
            languagePrefix={context.languagePrefix}
            pageID={context.id}
            {...context.headerProps}
          />
        ) : null}
        {context.heroProps ? (
          <Hero hasVideo={true} variant="video" {...context.heroProps} />
        ) : null}
        {context.introProps ? <Intro {...context.introProps} /> : null}
        {context.hotelsPreviewProps ? (
          <>
            <Media greaterThanOrEqual="desktopSmall">
              <HotelsPreview {...context.hotelsPreviewProps} />
            </Media>
            <Media lessThan="desktopSmall">
              <HotelsPreviewMobile {...context.hotelsPreviewProps} />
            </Media>
          </>
        ) : null}
        {context.zigZagsProps ? <ZigZags {...context.zigZagsProps} /> : null}
        {context.locationsSliderProps ? (
          <LocationsSlider {...context.locationsSliderProps} />
        ) : null}
        {context.followUsProps ? <FollowUs {...context.followUsProps} /> : null}
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            languagePrefix={context.languagePrefix}
            {...context.footerProps}
          />
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.main``
