import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  disabled?: boolean
  onClick?: (e: any) => void
}

export const Arrow = memo(function Arrow({
  direction = 'L',
  disabled,
  onClick,
}: Props) {
  return (
    <Container
      dial={5}
      className={disabled ? 'disabled' : undefined}
      onClick={onClick}
    >
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 3rem;
  height: 3rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.primaryDark};
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  @media (min-width: 1200px) {
    width: 6.25rem;
    height: 6.25rem;
  }

  &:hover {
    border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralDark2};
    background: ${({ theme }) => theme.colors.variants.neutralDark2};

    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }

  &.disabled {
    pointer-events: none;
  }

  svg {
    width: auto;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    stroke: ${({ theme }) => theme.colors.variants.primaryLight};
    stroke-width: 2;
    transition: 0.3s ease-in-out;

    @media (min-width: 1200px) {
      height: 1.25rem;
    }
  }
`
