import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Star } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  idx?: number
  image?: ImageProps
  languageCode: string
  place?: string
  rating?: number
  subtitle?: string
  title?: string
  websiteURL?: string
}

export const Item = memo(function Item({
  className,
  idx,
  image,
  languageCode,
  rating,
  subtitle,
  title,
  websiteURL,
}: Props) {
  const starsArray = Array.from(Array(rating), (_, i) => i * 1)

  return (
    <Container className={className} row stretch>
      <LeftSide>
        <Wrapper className="wrapper">
          {rating ? (
            <Rating row>
              {starsArray.map((_item, index) => (
                <StarItem key={index}>
                  <Star />
                </StarItem>
              ))}
            </Rating>
          ) : null}

          <Inner>
            {title ? <Title>{title}</Title> : null}

            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          </Inner>

          {websiteURL ? (
            <CTA
              label={useVocabularyData('come-in', languageCode)}
              target="_blank"
              rel="noopener"
              URL={websiteURL}
              variant="inverted"
            />
          ) : null}
        </Wrapper>
      </LeftSide>

      <RightSide className="image">
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}

        <Current>{(idx ? idx + 1 : 1).toString().padStart(2, '0')}</Current>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  &.active {
    opacity: 1;

    .wrapper {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    .image {
      opacity: 1;
      transform: none;
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
`

const Wrapper = styled.div`
  min-width: 31.25rem;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: calc(50% - 1.8rem);
  transform: translate(-50%, calc(-50% + 3.75rem));
  transition: 0.8s ease-in-out;
  z-index: 2;

  @media (max-width: 1439px) {
    left: calc(53%);
  }
`

const Rating = styled(FlexBox)`
  margin-bottom: 1.5625rem;
`

const StarItem = styled.div`
  svg {
    width: 0.4375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.primaryLight};
    margin-right: 0.5rem;
  }
`

const Inner = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  letter-spacing: 0.05em;
  line-height: 4.0625rem;
  text-transform: uppercase;
`

const Title = styled.div`
  font-weight: 600;
`

const Subtitle = styled.div`
  font-weight: 300;
`

const CTA = styled(Button)`
  margin-top: 4.0625rem;
  pointer-events: visible;
`

const RightSide = styled.div`
  width: 50%;
  position: relative;
  opacity: 0;
  transition: 0.8s ease-in-out;
  transform: translateY(-5.625rem);
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Current = styled.div`
  position: absolute;
  bottom: 5rem;
  right: 10.833vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 3.75rem;
  letter-spacing: 0.05em;
  line-height: 4.0625rem;
  opacity: 0.2;
  text-transform: uppercase;
  z-index: 1;
`
