import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  claim?: string
  description?: string
  title?: string
}

export const Intro = memo(function Intro({ claim, description, title }: Props) {
  return (
    <Container>
      <FadeIn>
        <Wrapper row={false} dial={5}>
          <FadeIn>
            <Icon src="/logo-dark.svg" alt="Logo" />
          </FadeIn>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {claim ? (
            <FadeIn>
              <Claim>{claim}</Claim>
            </FadeIn>
          ) : null}
        </Wrapper>
      </FadeIn>
    </Container>
  )
})

const Container = styled.section`
  margin: 15rem 10.833vw 11.25rem;

  @media (max-width: 1199px) {
    margin: 9rem 1.5rem 6.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  max-width: 58.375rem;
  min-height: 22.75rem;
  margin: 0 auto;
  padding: 2.8125rem 9.8vw 0;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight2};

  @media (max-width: 1199px) {
    padding: 2rem 1.875rem 0;
    min-height: 21.5rem;
  }
`

const Icon = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 10rem;
  padding: 0 2.8125rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};

  @media (max-width: 1199px) {
    max-height: 8rem;
  }

  @media (max-width: 480px) {
    max-height: 6.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1875rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 1.8125rem;
  }
`

const Description = styled.div`
  max-width: 22.5rem;
  margin-top: 0.375rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.875rem;
  text-align: center;
`

const Claim = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  min-width: max-content;
  padding: 0 2.8125rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.3em;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 480px) {
    padding: 0 1.5rem;
  }
`
