import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  images: ImagesProps[]
  facebookURL?: string
  instagramURL?: string
  languageCode: string
}

export const FollowUs = memo(function FollowUs({
  images,
  facebookURL,
  instagramURL,
  languageCode,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <FadeIn>
          <Box>
            <Inner row={false} dial={5}>
              <FadeInUp>
                <Title>
                  {useVocabularyData('follow-us', languageCode || 'it-IT')}
                </Title>
              </FadeInUp>
              <FadeInUp>
                <Hashtag>{'#ipalazzi'}</Hashtag>
              </FadeInUp>
              <FadeInUp>
                <Socials row dial={5}>
                  <Social
                    aria-label="Facebook"
                    href={facebookURL}
                    rel="noopener"
                    target="_blank"
                  >
                    <Icon
                      src={'/icon-facebook-black.svg'}
                      width="8"
                      height="16"
                      alt="Facebook"
                    />
                  </Social>
                  <Social
                    aria-label="Instagram"
                    href={instagramURL}
                    rel="noopener"
                    target="_blank"
                  >
                    <Icon
                      src={'/icon-instagram-black.svg'}
                      width="16"
                      height="16"
                      alt="Instagram"
                    />
                  </Social>
                </Socials>
              </FadeInUp>
            </Inner>
          </Box>
        </FadeIn>

        <FadeInUp>
          <Images row wrap dial={5}>
            {images.map((item, index) => (
              <LazyLoadComponent key={index}>
                <StyledImage {...item.image} />
              </LazyLoadComponent>
            ))}
          </Images>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 18.75rem 10.833vw 9.75rem;

  @media (max-width: 1439px) {
    margin: 18.75rem 0 9.75rem;
  }

  @media (max-width: 1199px) {
    margin: 15.5rem 1.5rem 6rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 70.5rem;
  margin: 0 auto;
  z-index: -1;
`

const Box = styled.div`
  position: absolute;
  top: -6.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: 78%;
  height: 91%;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight2};
`

const Inner = styled(FlexBox)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`

const Title = styled.h2`
  margin-bottom: 0.6875rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1875rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 1.8125rem;
  }
`

const Hashtag = styled.div`
  padding: 0 1.25rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
`

const Socials = styled(FlexBox)`
  margin-top: 1.875rem;
`

const Social = styled.a`
  &:not(:first-of-type) {
    margin-left: 1.25rem;
  }
`

const Icon = styled.img``

const Images = styled(FlexBox)`
  margin-left: -2.25rem;
  margin-bottom: -2.25rem;
  z-index: 1;

  @media (max-width: 1024px) {
    margin-left: -1.75rem;
    margin-bottom: -1.75rem;
  }

  @media (max-width: 767px) {
    margin-left: -1.5rem;
    margin-bottom: -1.5rem;
  }

  @media (max-width: 480px) {
    margin-left: 0;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: calc(25% - 2.25rem);
  aspect-ratio: 1;
  margin-left: 2.25rem;
  margin-bottom: 2.25rem;
  z-index: -1;

  @media (max-width: 1024px) {
    width: calc(33% - 1.75rem);
    margin-left: 1.75rem;
    margin-bottom: 1.75rem;
  }

  @media (max-width: 767px) {
    width: calc(50% - 1.5rem);
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-left: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
