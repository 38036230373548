import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const ZigZags = memo(function ZigZags({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      {items.map((item, index) => (
        <Item
          key={index}
          variant={index % 2 ? 'inverted' : 'default'}
          {...item}
        />
      ))}
    </Container>
  )
})

const Container = styled.section``
