import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useMediaQuery from 'app/utils/useMediaQuery'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

const SCROLL_OFFSET = 100 /* Use integer values that are multiples of 100  */
const HEIGHT_OFFSET = 70

export interface Props {
  claim?: string
  items: ItemProps[]
}

export const HotelsPreview = memo(function HotelsPreview({
  claim,
  items,
}: Props) {
  if (!items) {
    return null
  }

  const containerRef = useRef<any>()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideIdx, setSlideIdx] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 0,
    },
    drag: false,
    slides: items.length,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
  })

  useEffect(() => {
    const containerRect = containerRef.current.getBoundingClientRect()
    const containerTop = containerRef.current.offsetTop
    const containerHeight = containerRect.height
    const scrollDistance = containerHeight - window.innerHeight

    const getProgress = (start: any, distance: any, scroll: any) => {
      return Math.min(1, Math.max(0, (scroll - start) / distance))
    }

    const handleScroll = () => {
      const scrollPos = window.scrollY
      const progress = getProgress(containerTop, scrollDistance, scrollPos)

      setSlideIdx(Math.ceil((progress * 10) / items.length))
      instanceRef.current?.moveToIdx(slideIdx)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [currentSlide, slideIdx])

  const calculateOffset = (offset: number) => {
    const mobileSize = useMediaQuery('(max-width: 1199px)')
    const desktopSize = useMediaQuery('(max-width: 1920px)')

    if (mobileSize) {
      offset = offset * 2
    } else if (desktopSize) {
      offset = offset * 1.4
    }
    return offset
  }

  return (
    <Container
      className="blended"
      ref={containerRef}
      style={{ height: calculateOffset(HEIGHT_OFFSET) * items.length + 'vh' }}
    >
      <Sticky>
        <Places row={false}>
          {uniq(items).map((item, index) => (
            <Place
              className={currentSlide === index ? 'active' : undefined}
              key={index}
              onClick={() => {
                instanceRef.current?.moveToIdx(index)
                setSlideIdx(index)
                setCurrentSlide(index)

                index === items.length - 1
                  ? window.scroll({
                      top: containerRef.current.offsetTop + window.innerHeight,
                      left: 0,
                    })
                  : null
              }}
            >
              {item.place}
            </Place>
          ))}
        </Places>

        {claim ? (
          <Claim dial={4} row>
            {claim}
          </Claim>
        ) : null}

        <Slider
          ref={sliderRef}
          style={{ width: SCROLL_OFFSET * items.length + 'vw' }}
        >
          {uniq(items).map((item, index) => (
            <Item
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              idx={index}
              {...item}
            />
          ))}
        </Slider>
      </Sticky>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
`

const Sticky = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (min-width: 1200px) {
    mix-blend-mode: difference;

    @supports (font: -apple-system-body) and (-webkit-appearance: none) {
      mix-blend-mode: luminosity;
    }
  }
`

const Places = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 10.833vw;
  transform: translateY(-50%);
  z-index: 11;

  &.sticky {
    position: fixed;
  }
`

const Place = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05em;
  line-height: 1.375rem;
  margin-top: 1.875rem;
  position: relative;
  transition: 0.6s ease-in-out;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    padding-left: 4.5rem;

    &:before {
      width: 3.75rem;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:before {
    content: '';
    width: 0;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    top: 50%;
    left: 0;
    transition: 0.6s ease-in-out;
  }
`

const Claim = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.4em;
  line-height: 1.125rem;
  position: absolute;
  bottom: 6.25rem;
  left: 10.833vw;
  text-transform: uppercase;
  z-index: 11;

  &.sticky {
    position: fixed;
  }

  &:after {
    content: '';
    width: 16.319vw;
    max-width: 14.6875rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    margin-left: 1.25rem;
  }
`

const Slider = styled.div`
  display: flex;
  height: 100vh;
  outline: none;
`
