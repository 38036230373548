import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  claim?: string
  items: ItemProps[]
}

export const HotelsPreviewMobile = memo(function HotelsPreviewMobile({
  claim,
  items,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      {claim ? (
        <FadeIn>
          <Inner row dial={4}>
            <Claim>{claim}</Claim>
          </Inner>
        </FadeIn>
      ) : null}

      {uniq(items).map((item, index) => (
        <Item key={index} idx={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.variants.primaryDark};
`

const Inner = styled(FlexBox)`
  width: 100%;
  padding: 1.875rem 1.5rem;

  &:after {
    content: '';
    width: 100%;
    height: 0.0625rem;
    margin-left: 1.15rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
  }
`

const Claim = styled.div`
  min-width: max-content;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.4em;
  line-height: 1.125rem;
  text-transform: uppercase;
  z-index: 1;
`
